<template>
	<div class="page-container">
		<div class="page_head">
			<div class="page_content">
				<div class="left_img">
					<img src="@/assets/eleme/page_left.png"></img>
				</div>
				<div class="center_text">
					<span>饿了么领券下单拿佣金</span>
				</div>
				<div class="right_img">
					<img src="@/assets/eleme/page_right.png"></img>
				</div>
			</div>
			
			<div class="coupon">
				<div class="coupon_one">
					<img src="@/assets/eleme/coupon_one.png"></img>
					<span>先领券</span>
				</div>
				<div class="line"></div>
				<div class="coupon_one">
					<img src="@/assets/eleme/coupon_two.png"></img>
					<span>再下单</span>
				</div>
				<div class="line"></div>
				<div class="coupon_one">
					<img src="@/assets/eleme/coupon_three.png"></img>
						<span>等返佣</span>
				</div>
			</div>
			<div @click="_goCoupon()" class="btn_coupon">
				<span>领券点外卖</span>
			</div>
			<div class="btn_coupon_net">
				<img src="@/assets/eleme/btn_coupon.png"></img>
			</div>
		</div>
		<div class="page_comment">
			<span class="page_text">{{token}}</span>
			<span class="page_text_one">(点击复制淘口令）</span>
			<div v-clipboard:copy="token" @success="_goClipboard" class="btn_comment_one">
				<span>复制口令赚钱</span>
			</div>
			<div class="btn_coupon_net btn_coupon_pos">
				<img src="@/assets/eleme/btn_coupon.png"></img>
			</div>
		</div>
		<div class="page_footer">
			<div class="footer_content">
				<div class="footer_left">
					<img src="@/assets/eleme/footer_left.png"></img>
				</div>
				<div class="footer_center">
					<span>返利注意事项</span>
				</div>
				<div class="footer_left">
					<img src="@/assets/eleme/footer_right.png"></img>
				</div>
			</div>
			<ol class="footer_text">
				<li>复制口令必须打开最新版本手机淘宝(iOSV8.111/ AndroidV8.110及以上)领券，领券后7天内用券下单均有返佣跳转到其余版本的手机淘宝账号须与饿了账号绑定。</li>
				<li>下单均有佣金，佣金计算以实际支付金额为准;下单后约30分钟同步到订单，确认收货后的次月25日结算佣金。</li>
				<li>口令复制后既可以自己购买也可以分享给其他人，当其他人使用你的口令下单后，佣金归你</li>
				<li>仅限饿了么品质联盟商户订单有返佣。</li>
				<li>如果饿了么活动页上推荐的店铺你不喜欢，请打开饿了么APP，搜索你喜欢的门店，领券后7天内用券下单仍然有返佣。</li>
			</ol>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {getElemeTaoToken} from '@/api/eleme'
import ls from "@/libs/localstore"
import {Loading, Dialog} from 'vant'
import VueClipboards from "vue-clipboards";

Vue.use(VueClipboards).use(Loading).use(Dialog)

export default {
	components: {},
	data() {
		return {
			token: '',
			target: ''
		};
	},
	computed: {},
	created() {},
	mounted() {
		this._loadData()
	},
	methods: {
		_goClipboard() {
			if(this.token) {
				this.$toast("复制成功，打开手机tao寳领取");
			}else{
				this._showDownload()
			}
		},
		_goCoupon() {
			if(this.target) {
				window.location.href = this.target
			}else{
				this._showDownload()
			}
		},
		_showDownload() {
			Dialog.alert({
				title: '提示',
				message: '饿了么红包仅限淘宝渠道会员使用，请下载淘街坊app，并且进行授权淘宝，即可享受天天红包和大额优惠券哦',
				theme: 'round-button',
			}).then(() => {
				window.location.href = 'http ://www.taojiefang.cn/'
			});
		},
		_loadData() {
			this.$toast.loading({
        duration: 0,
        loadingType: "spinner",
        message: "加载中...",
        mask: false,
        forbidClick: true
			});
			
			let params = {
				 
			}
			getElemeTaoToken(params).then(res => {
				this.$toast.clear();
				if(res.errCode === 0) {
					this.token = res.data.tao_token
					this.target = res.data.target
				}
			}).catch(err => {
				this.$toast.clear();
				if(err.errCode === 'ERR_07000') {
					this._showDownload()
				}else{
					this.$toast(err.errMsg)
				}
			})
		}
	}
};
</script>

<style scoped>
.page-container {
	min-height: 100vh;
	background: #B0D8FE url('http://img.quanminhuyu.com/FobUHNY4_7_Yk7R8Q2Zx4WqxvfJn?imgMogr2/thumbnail/750x') no-repeat top center;
	background-size: 100% auto;
	padding: 94px 0 36px;
}
.page_head{
	margin: 0 12px;
	background: #FFFFFF;
	box-shadow: 0 2px 7px 0 #83BBF4;
	border-radius: 10px;
	padding: 24px 0 38px 0;
	position: relative;
}
.page_content{
	display: flex;
	justify-content: center;
	align-items: center;
}
.left_img{
	width: 27px;
	height: 9px;
	margin-bottom: 9px;
}
.left_img img{
	width: 100%;
	height: 100%;
}
.center_text{
	font-size: 20px;
	color: #4A8CE6;
	text-align: center;
	margin: 0 6px;
}
.right_img{
	width: 27px;
	height: 9px;
	margin-bottom: 9px;
}
.right_img img{
	width: 100%;
	height: 100%;
}
.coupon_one{
	width: 42px;
	height: 42px;
}
.coupon_one img{
	width: 100%;
	height: 100%;
}
.coupon{
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 14px;
	color: #4D4D4D;
}
.line{
	width: 46px;
	height: 2px;
	background: #93C7F2;
	margin: 0 12px;
}
.btn_coupon{
	margin: 32px auto 0;
	width: 311px;
	height: 44px;
	background-image: linear-gradient(180deg, #FFDB2E 0%, #FF9F04 100%);
	box-shadow: 0 2px 4px 0 #FFDCA2;
	border-radius: 22px;
	text-align: center;
	-webkit-animation: free_download 0.4s linear alternate infinite;
	animation: free_download 0.4s linear alternate infinite;
}
@-webkit-keyframes free_download{
		0%{-webkit-transform:scale(0.9);}
		100%{-webkit-transform:scale(1);}
}
@keyframes free_download{
	0%{transform:scale(0.9);}
	100%{transform:scale(1);}
}
.btn_coupon span{
	font-size: 20px;
	color: #822601;
	line-height: 45px;
}
.btn_coupon_net{
	width: 200px;
	height: 50px;
	position: absolute;
	bottom: -15%;
	left: 50%;
	z-index: 99;
	transform: translate(-50%,0%);
}
.btn_coupon_pos{
	bottom: -12% !important;
}
.btn_coupon_net img{
	width: 100%;
	height: 100%;
}
.page_comment{
	margin: 16px 12px 0;
	background: #FFFFFF;
	box-shadow: 0 2px 12px 0 #83BBF4;
	border-radius: 10px;
	padding: 37px 20px 45px;
	text-align: center;
	position: relative;
}
.page_text{
	font-size: 16px;
	color: #4D4D4D;
	display: block;
}
.page_text_one{
	font-size: 14px;
	color: #9B9B9B;
	margin: 16px 0 20px 0;
	display: block;
}
.btn_comment_one{
	margin: 0 auto;
	width: 311px;
	height: 44px;
	background-image: linear-gradient(180deg, #FFDB2E 0%, #FF9F04 100%);
	box-shadow: 0 2px 4px 0 #FFDCA2;
	border-radius: 22px;
}
.btn_comment_one span{
	font-size: 20px;
	color: #822601;
	text-align: center;
	line-height: 45px;
}
.btn_comment_two{
	margin:14px auto 0 ;
	width: 311px;
	height: 44px;
	background-image: linear-gradient(180deg, #A3D9F5 0%, #4FA3FC 100%);
	box-shadow: 0 2px 4px 0 #D5EAFF;
	border-radius: 22px;
}
.btn_comment_two span{
	font-size: 20px;
	color: #FFFFFF;
	text-align: center;
	line-height: 45px;
}
.page_footer{
	margin: 16px 12px;
	background: #FFED95;
	box-shadow: 0 2px 12px 0 #83BBF4;
	border-radius: 10px;
	padding: 37px 14px 20px;
}
.footer_content{
	display: flex;
	justify-content: center;
	align-items: center;
}
.footer_left{
	width: 27px;
	height: 9px;
	margin-bottom: 9px;
}
.footer_left img{
	width: 100%;
	height: 100%;
}
.footer_center{
	font-size: 20px;
	color: #4A8CE6;
	margin: 0 6px;
}
.footer_text{
	padding-left: 14px;
	margin-top: 12px;
	font-size: 12px;
	color: #4A8CE6;
	list-style-type:decimal;
}
.footer_text li {
	line-height: 18px;
	margin-bottom: 10px;
}
</style>
